import React from 'react';
import { BrowserRouter as BrowserRouter, Routes, Route } from 'react-router-dom';
import FormTemplate from './Components/FormTemplate';
import Forms from './Components/Forms';
import './App.css'
import RedirectToExternal from './Components/RedirectToExternal';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/export' element={<Forms />}/>
        <Route path='*' element={<RedirectToExternal />}/>
        <Route path='/press' element={<FormTemplate />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
