import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToExternal = () => {
  useEffect(() => {
    window.location.href = 'https://commb.ca/en-CA/';
  }, []);

  return null; // This component doesn't render anything
};

export default RedirectToExternal;
