// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCO0EpDJAF8z7w6HNwon5g-fovln_4xNis",
  authDomain: "newmember-press.firebaseapp.com",
  projectId: "newmember-press",
  storageBucket: "newmember-press.appspot.com",
  messagingSenderId: "907321568428",
  appId: "1:907321568428:web:d1f13bfade4ee4717f0309",
  measurementId: "G-950JXTVTJJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app); 

export { app, db, storage }; 